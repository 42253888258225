import { DefaultTheme } from 'styled-components';

declare module "styled-components" {
  export interface DefaultTheme {
    bg: string;
    color: string;
    bgNav: string;
    tabIconColor: string;
    tabActiveIconColor: string;
    lightGray: string;
    tabActiveBackground: string;
    bgTabItems: string;
    bgDislike: string;
    subtitleColor: string;
    imageHeight?: string;
  }
}

export interface Theme {
  'dark': DefaultTheme;
  'light': DefaultTheme;
}

export const themes: Theme = {
  light: {
    bg: '#F3F3F3',
    color    : '#161617',
    bgNav: '#FFFFFF',
    tabActiveIconColor: '#1A5BE1',
    tabActiveBackground: '#FBFBFB',
    tabIconColor: '#EDEDED',
    lightGray: '#232232',
    bgTabItems: '#FBFBFB',
    bgDislike: '#FFFFFF',
    subtitleColor: '#232232',
  },
  dark: {
    bg: '#181828',
    color    : '#FEFEFE',
    bgNav: '#2C2B3E',
    tabActiveIconColor: '#FFFFFF',
    tabActiveBackground: '#1F1F31',
    tabIconColor: '#181828',
    lightGray: '#fefefe',
    bgTabItems: '#1F1F31',
    bgDislike: '#222243',
    subtitleColor: '#FEFEEF',
  }
}