import styled from 'styled-components'

export const BackgroundImage = styled.img.attrs({
  className: "absolute top-0"
})`
  min-height: ${({theme})=> theme.imageHeight};
  object-fit: cover;
  filter: blur(4px);
  z-index: 1;
  border-bottom-right-radius: 32px;
  border-bottom-left-radius: 32px;
  transition: 1.35s;
`
export const Image = styled.img.attrs({
  className: "absolute top-0"
})`
  min-height: ${({theme})=> theme.imageHeight};
  object-fit: contain;
  z-index: 1;
  transition: 1.35s;
`
export const SportTag = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 63px;
  width: 63px;
  backdrop-filter: blur(20px);
  border-radius: 18px;
  right: 22px;
  top: 22px;
  z-index: 9;
`
 export const ImageIcon = styled.img`
   height: 38px;
   z-index: 2;
  `
export const LikeButton = styled.button`
  background: linear-gradient(125.02deg, #236BFE -17.11%, #063BA8 98.58%);
  width: 81px;
  height: 81px;
  border-radius: 50%;
  box-shadow: 0px 10px 25px 0px #236BFE33;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    fill: #fff;
  }
`
export const DislikeButton = styled.button`
  background: ${({theme})=> theme.bgDislike};
  width: 51px;
  height: 51px;
  border-radius: 50%;
  margin-right: 22px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Label = styled.p.attrs({
  className: "absolute"
})`
  top: calc(66vh - 100px);
  height: 100px;
  color: #FEFEFE;
  background: linear-gradient(360deg, #000000 0%, #000000 58.85%, rgba(0, 0, 0, 0) 100%);
  font-size: 34px;
  z-index: 9;
  right: 0;
  left: 0;
  padding: 26px 21px;
  border-bottom-right-radius: 32px;
  border-bottom-left-radius: 32px;
`
export const LikeContainer = styled.div.attrs({
  className: "flex justify-center items-center"
})`
  margin-top: 24px;
`
