import { createPortal } from "react-dom"
import styled from "styled-components"
import { useSpring, animated } from 'react-spring';
import { useState } from 'react';

interface Props {
  children: JSX.Element | JSX.Element[];
  isOpen: boolean
}

const StyledDiv = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
`

const StyledLoading = styled.div`
  background: rgba(24, 24, 40, 0.8);
  width: 170px;
  height: 170px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledCircle = styled.div`
  width: 264px;
  height: 264px;
  background: rgba(24, 24, 40, 0.24);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Loading = ({ children, isOpen }: Props) => {
  const [flip, set] = useState(false)
  const loadingStyleProps = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    reset: true,
    reverse: flip,
    onRest: () => set(!flip),
    config: {
      friction: 52
    }
  })
  if(!isOpen) return null

  const loadingDiv = document.getElementById('loading') as HTMLElement

  return createPortal(
    <StyledDiv>
      <animated.div style={loadingStyleProps}>
        <StyledCircle>
          <StyledLoading>
            {children}
          </StyledLoading>
        </StyledCircle>
      </animated.div>
    </StyledDiv>,
    loadingDiv
  )
}

export default Loading