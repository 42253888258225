const CloseIcon = (props:any = {}) => (
  <svg width={16} height={16} {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.998921 0.998822C1.59792 0.399822 2.56909 0.399822 3.16809 0.998822L7.86796 5.69869L12.5678 0.998822C13.1668 0.399823 14.138 0.399822 14.737 0.998822C15.336 1.59782 15.336 2.56899 14.737 3.16799L10.0371 7.86786L14.737 12.5677C15.336 13.1667 15.336 14.1379 14.737 14.7369C14.138 15.3359 13.1668 15.3359 12.5678 14.7369L7.86796 10.037L3.16809 14.7369C2.56909 15.3359 1.59792 15.3359 0.998921 14.7369C0.399921 14.1379 0.399921 13.1667 0.998921 12.5677L5.69879 7.86786L0.998921 3.16799C0.399921 2.56899 0.399921 1.59782 0.998921 0.998822Z" fill="#D36060"/>
  </svg>
);

export default CloseIcon;

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.998921 0.998821C1.59792 0.399822 2.56909 0.399822 3.16809 0.998821L7.86796 5.69869L12.5678 0.998821C13.1668 0.399822 14.138 0.399822 14.737 0.998821C15.336 1.59782 15.336 2.56899 14.737 3.16799L10.0371 7.86786L14.737 12.5677C15.336 13.1667 15.336 14.1379 14.737 14.7369C14.138 15.3359 13.1668 15.3359 12.5678 14.7369L7.86796 10.037L3.16809 14.7369C2.56909 15.3359 1.59792 15.3359 0.998921 14.7369C0.399921 14.1379 0.399921 13.1667 0.998921 12.5677L5.69879 7.86786L0.998921 3.16799C0.399921 2.56899 0.399921 1.59782 0.998921 0.998821Z" fill="#D36060"/>
</svg>
