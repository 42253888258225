import { combineReducers } from '@reduxjs/toolkit';
import user from './slices/userSlice'
import loading from './slices/loadingSlice'

const rootReducer = combineReducers ({
  user,
  loading
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;