import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Layout,
  Login,
  StyledButton,StyledInput
} from './components'
import { useFirebase } from '../../hooks/useFirebase/index'
import GlobalStyle from '../layout/GlobalStyle'
import { useForm } from '../../hooks/useForm'

const SignUp = () => {

  const { handleForm, email, password, confirmPassword } = useForm({
    email: '',
    password: '',
    confirmPassword: ''
  })

  const { signUp } = useFirebase()

  return (
    <Layout>
      <GlobalStyle/>
      <h1>Welcome</h1>
      <small>Sign in to start</small>
      <Login>
        <StyledInput
          className="mb-2"
          type="text"
          placeholder="Email"
          value={email}
          onChange={({target}) => handleForm(target.value, 'email')}
        />
        <StyledInput
          className="mb-2"
          type="text"
          placeholder="Confirm Password"
          value={password}
          onChange={({target}) => handleForm(target.value, 'password')}
        />
        <StyledInput
          type="text"
          placeholder="Repeat Password"
          value={confirmPassword}
          onChange={({target}) => handleForm(target.value, 'confirmPassword')}
        />
        <small>Forgot your password?</small>
        <StyledButton
          disabled={password !== confirmPassword}
          onClick={(e) => {
            e.preventDefault()
            signUp(email, password)
          }}
        >
          Signup
        </StyledButton>
        <Link to="/signin">
          <small>Login </small>
        </Link> 
      </Login>
    </Layout>
  )
}

export default SignUp
