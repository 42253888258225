import messi from '../../assets/messi.png'
import { useFirebase } from '../../hooks/useFirebase/index';
import { Link } from 'react-router-dom';
import { StyledHeroContainer, StyledFooterContainer } from './components'

const Welcome = () => {
  return (
    <div>
        <StyledHeroContainer>
          <img src={messi} alt="Messi" />
        </StyledHeroContainer>
        <StyledFooterContainer>
          <h1>Discover Your Best Sport With Us</h1>
          <p>Click the button to Login</p>
          <Link to="/signin">
            <button>Login</button>
          </Link>
        </StyledFooterContainer>
    </div>
  )
}

export default Welcome;