import { createSlice } from "@reduxjs/toolkit"

export interface UserState {
  id: string
}

const initialState: UserState = {
  id: ''
}

const UserSlice = createSlice({
  name: 'user',
  initialState,  
  reducers: {
    setUser: (state, { payload }) => {
      state.id = payload
    },
  }
})

export default UserSlice.reducer
export const { setUser } = UserSlice.actions