import { Link } from 'react-router-dom'
import GlobalStyle from '../layout/GlobalStyle'
import { Layout, Login, StyledButton, StyledInput } from './components'
import { useFirebase } from '../../hooks/useFirebase/index'
import { useForm } from '../../hooks/useForm'

const Auth = () => {

  const { signIn } = useFirebase()
  const { handleForm, email, password } = useForm({
    email: '',
    password: ''
  })

  return (
    <Layout>
      <GlobalStyle />
      <h1>Welcome</h1>
      <small>Sign in to start</small>
      <Login>
        <StyledInput
          className="mb-2"
          type="text"
          placeholder="User"
          value={email}
          onChange={({target}) => handleForm(target.value, 'email')}
        />
        <StyledInput
          type="password"
          placeholder="Password"
          value={password}
          onChange={({target}) => handleForm(target.value, 'password')}
        />
        <small>Forgot your password?</small> 
        <StyledButton onClick={(e) => {
          e.preventDefault()
          signIn('asdqwe123@asd.asd', '123qwe')
          }}>
          Login
        </StyledButton>
        <Link to="/signup">
          <small>Signup </small>
        </Link>         
      </Login>
    </Layout>
  )
}

export default Auth