import { useState, useEffect } from "react"
import { SportsResponse } from '../interfaces/SportsInterface';
import sportDB from "../api/sportDB";

export const useSportsDB = () => {
  
  const [sports, setSports] = useState<SportsResponse>()
  const [loadingSports, setLoadingSports] = useState(true)

  useEffect(() => {
    getSports()
  }, [])
    
  const getSports = async () => {
    try {
      setLoadingSports(false)
      const sportsResponse = await sportDB.get<SportsResponse>('/all_sports.php')
      setSports(sportsResponse.data)
      setLoadingSports(true)
    } catch (error) {
      console.log(error)
    }
  }

  const removeSport = () => {
    const sportWithoutLike = sports
      ? {sports: sports.sports.filter((_, i: number) => i !== 0)}
      : sports
    setSports(sportWithoutLike)
  }

  return { sports, removeSport, loadingSports }

}