import styled from "styled-components"

export const StyledContainer = styled.div`
color: ${(p) => p.theme.color};
border-radius: 12px;
margin-top: 102px;
margin-left: 32px;
margin-right: 32px;
`
export const LikedSport = styled.div`
background-color: ${(p) => p.theme.bgTabItems};
border-radius: 12px;
margin-top: 14px;
display: flex;
align-items: center;
flex-grow: 1;
& svg {
  fill: ${(p) => p.theme.tabActiveIconColor};
  width: 20%;
}
`
export const Image = styled.img`
object-fit: cover;
height: 77px;height: 77px;
border-radius: 12px;
width: 80%;
`
export const Title = styled.p`
font-size: 42px;
font-style: normal;
font-weight: 700;
line-height: 51px;
letter-spacing: -0.055em;
text-align: left;
color: ${(p) => p.theme.color};
`
export const Subtitle = styled.p`
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 27px;
letter-spacing: 0em;
text-align: left;  
color: ${(p) => p.theme.subtitleColor};
`

export const StyledLike = styled.i`
  fill: ${(p) => p.theme.subtitleColor};
  width: 20%;
  display: flex;
`