import { useFirebase } from '../../hooks/useFirebase/index'
import { useSportsDB } from '../../hooks/useSportsDB'
import HeartIcon from '../icons/HeartIcon'
import CloseIcon from '../icons/CloseIcon'
import {
  StyledContainer,
  LikedSport,
  Image,
  Title,
  Subtitle
} from './components'

const History = () => {

  const { loadingLikes, likedSports } = useFirebase()
  const { sports } = useSportsDB()

  const getImage = (sportId: number) => {
    return sports?.sports
      .find(sport => sport.idSport === sportId)
      ?.strSportThumb
  }

  return (
    <StyledContainer>
      <Title>History</Title>
      <Subtitle>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Subtitle>
      {
        Object.keys(likedSports).map( (key: any) => (
          <>
            <LikedSport key={key}>
              <Image
                src={getImage(key)}
                alt={getImage(key)}
              />
              { likedSports[key].like
                ? <HeartIcon width="20" height="20" /> 
                : <CloseIcon width="20" height="20" /> }
            </LikedSport>
          </>
        ))
      }
    </StyledContainer>
  )
}

export default History
