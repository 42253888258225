import { NavLink } from "react-router-dom"
import styled from 'styled-components'

const StyledHeroContainer  = styled.div`
  background: ${(p: any) => p.theme.bg};
  & img {
    object-fit: cover;
    min-height: 100vw;
  }`;

const StyledFooterContainer = styled.div`
position: fixed;
bottom: 0;
background: ${(p: any) => p.theme.bgNav};
border-radius: 36px 36px 0 0;
padding: 32px 28px;
& h1 {
  font-size: 28px;
  color: ${(p: any) => p.theme.color};
  margin-bottom: 12px;
}
& p {
  font-size: 18px;
  font-weight: 400;
  color: ${(p: any) => p.theme.lightGray};
  margin-bottom: 54px;
}
& button {
  color: #FEFEFE;
  padding: 22px 38px;
  background: linear-gradient(99deg, #236BFE 6.69%, #0D4ED3 80.95%);
  box-shadow: 0px 4px 30px rgba(34, 105, 251, 0.8);
  border-radius: 25px;
}
`;

const StyledNavLink = styled(NavLink)`
  color: #FEFEFE;
  padding: 22px 38px;
  background: linear-gradient(99deg, #236BFE 6.69%, #0D4ED3 80.95%);
  box-shadow: 0px 4px 30px rgba(34, 105, 251, 0.8);
  border-radius: 25px;
`

export { StyledHeroContainer, StyledFooterContainer, StyledNavLink };