import {
  collection,
  doc,
  setDoc,
  getDoc 
} from 'firebase/firestore'
import { 
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword
} from "firebase/auth"
import { useState, useEffect } from 'react'
import { useFirestore } from "./useFirestore"
import { useAppDispatch, useAppSelector } from '../index'
import { setUser } from '../../store/slices/userSlice'
import { showLoading, hideLoading } from '../../store/slices/loadingSlice'

interface SportLike {
  sportId: number;
  like: boolean;
  name: string;
}

export const useFirebase = () => {

  const userId = useAppSelector(store => store.user.id)
  const AppDispatch = useAppDispatch()
  const { db } = useFirestore()
  const dispatch = useAppDispatch()
  const [loadingLikes, setLoadingLikes] = useState(false)
  const [loading, setLoading] = useState(false)
  const [likedSports, setLikedSports] = useState<any>({})

  useEffect(()=>{
    getLikedSports()
  }, [])

  const addSportLike = async (sport: SportLike) => {
    try {
      setLoading(true)
      dispatch(showLoading())
      const sportDB = collection(db, 'sports')
      const userRef = doc(sportDB, userId)
      const newSport = {[sport.sportId]: sport}
      await setDoc(userRef, newSport, {merge: true})
      setLoading(false)
      dispatch(hideLoading())
    } catch (error) {
      console.log(error);
    }
  }

  const getLikedSports = async () => {
    setLoadingLikes(true)
    const sportDB = collection(db, 'sports')
    const likedSportsRef = doc(sportDB, userId)
    const likedSportsDoc = await getDoc(likedSportsRef);
    if (likedSportsDoc.exists()) {
      setLikedSports(likedSportsDoc.data())
    }
    setLoadingLikes(false)
  }

  const signIn = async (email: string, password: string) => {
    const auth = getAuth()
    const { user } = await signInWithEmailAndPassword(auth, email, password)
    AppDispatch(setUser(user.uid))
  }

  const signUp = async (email: string, password: string) => {
    const auth = getAuth()
    const { user } = await createUserWithEmailAndPassword(auth, email, password)
    AppDispatch(setUser(user.uid))
  }

  return {
    addSportLike,
    signIn,
    signUp,
    loading,
    loadingLikes,
    likedSports
  }
}
