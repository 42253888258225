import { useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Home from "./components/home";
import Layout from "./components/layout";
import History from "./components/history";
import Welcome from "./components/welcome";
import StyledTheme from "./components/layout/StyledTheme";
import { useAppSelector } from './hooks/index';
import Auth from "./components/auth";
import SignUp from './components/auth/SignUp';

const App = () =>  {

  const userId = useAppSelector(store => store.user.id)
  
  
  return (
    <StyledTheme>
      <BrowserRouter>
        <Switch>
          <Route exact path="/welcome" >
            {userId.trim() ? <Redirect to="/" /> : <Welcome />}
          </Route>
          <Route exact path="/signin">
            {userId.trim() ? <Redirect to="/" /> : <Auth />}            
          </Route>
          <Route exact path="/signup">
            {userId.trim() ? <Redirect to="/" /> : <SignUp />}
          </Route>
          <Layout>
            <Route exact path="/">
              {!userId.trim() ? <Redirect to="/welcome" /> : <Home />}
            </Route>
            <Route exact path="/history" >
              {!userId.trim() ? <Redirect to="/welcome" /> : <History />}
            </Route>
          </Layout>
        </Switch>      
      </BrowserRouter>
    </StyledTheme>
  )
}

export default App
