import { useState } from 'react'
import { ThemeProvider } from 'styled-components'
import ButtonTheme from './ButtonTheme';
import { themes, Theme } from './Themes';
import { useAppSelector } from '../../hooks/index';

interface Props {
  children: JSX.Element | JSX.Element[];
}

const StyledTheme = ({ children }: Props) => {

  const [theme, setTheme] = useState<keyof Theme>('light');
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light'
    setTheme(newTheme)
  }

  const isLoading = useAppSelector(store => store.loading.isLoading)
  const imageHeight = isLoading ? '100vh' : '66vh'
  const customTheme = {...themes[theme], imageHeight}

  return (
    <ThemeProvider theme={customTheme}>      
      <ButtonTheme theme={theme} toggleTheme={toggleTheme} />
      {children}
    </ThemeProvider>
  )
}


export default StyledTheme