import styled from "styled-components"

const Button = styled.button.attrs({
  className: "fixed w-10 h-10"
})`
  z-index: 3;
  font-size: 25px;
  height: 63px;
  width: 63px;
  background-color: ${(p: any) => p.theme.bgNav};
  border-radius: 18px;
  left: 22px;
  top: 22px;
`

interface Props {
  toggleTheme: ()=>void;
  theme: string;
}

const ButtonTheme = ({ toggleTheme, theme }: Props) => {
  const getThemeIcon = () => theme === "light" ? `🌙` : `🌤️`;
  
  return (
    <Button onClick={toggleTheme}><span>{getThemeIcon()}</span></Button>
  )
}

export default ButtonTheme