import styled from 'styled-components'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`

const Login  = styled.form`
  background: ${(p: any) => p.theme.bg};
  & img {
    object-fit: cover;
    min-height: 100vw;
  }`;

const StyledInput = styled.input`
  display: block;
  background: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  border-radius: 18px;
  padding: 16px 12px;
`
const StyledButton = styled.button`
  display: block;
  margin: auto;
  color: #FEFEFE;
  padding: 22px 38px;
  background: linear-gradient(99deg, #236BFE 6.69%, #0D4ED3 80.95%);
  box-shadow: 0px 4px 30px rgba(34, 105, 251, 0.8);
  border-radius: 25px;
`


export { Layout, Login, StyledButton, StyledInput };