import { useRef, useEffect, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { useHistory } from "react-router-dom"
import { useSpring, animated } from 'react-spring'

import { useSportsDB } from "../../hooks/useSportsDB"
import { useFirebase } from "../../hooks/useFirebase"
import Loading from '../loading';
import HeartIcon from '../icons/HeartIcon';
import CloseIcon from '../icons/CloseIcon';

import {
  BackgroundImage,
  Image,
  SportTag,
  ImageIcon,
  LikeButton,
  DislikeButton,
  Label,
  LikeContainer
} from './components'

const Home = () => {
  const [isLike, setIslike] = useState(true)
  let carousel = useRef<HTMLDivElement | null>(null)
  let history = useHistory()
  const [animationStyles, animationAPI] = useSpring(() => ({ opacity: 1 }))

  const { addSportLike, loading } = useFirebase()
  const { sports, removeSport, loadingSports } = useSportsDB()

  const loadingIcon = isLike
    ? (
      <LikeButton
        onClick={() => sendLike(true)}
      >
        <HeartIcon width="36.45px" height="34.63px" />
      </LikeButton>
    ) : (
      <DislikeButton
        onClick={() => sendLike(false)}
      >
        <CloseIcon width="19.43" height="19.43"/>
      </DislikeButton>
    )

  const handleSwipe = useSwipeable({
    onSwipedLeft: ()=> sendLike(true),
    onSwipedRight: ()=> sendLike(false)
  })

  const refPassthrough = (el: HTMLDivElement) => {
    handleSwipe.ref(el)
    carousel.current = el
  }

  const sendLike = async (like: boolean) => {
    setIslike(like)
    if(sports?.sports) {
      const sportDoc = {
        sportId: sports?.sports[0].idSport,
        name: sports?.sports[0].strSport,
        like,
      }
      
      await addSportLike(sportDoc)
      if (sports?.sports.length === 1 ) history.push("/history")
      animationAPI.start({ opacity: 0 })
      setTimeout((()=>{
        removeSport()
        animationAPI.start({ opacity: 1 })
      }), 250)
    }
  }

  return (
    sports?.sports
      ? (
        <div>
          <Loading isOpen={loading}>
            {loadingIcon}
          </Loading>
          <animated.div
            style={animationStyles}
            {...handleSwipe}
            ref={refPassthrough}
          >
            <SportTag>
              <ImageIcon
                src={sports?.sports[0].strSportIconGreen}
                alt={sports?.sports[0].strSportDescription}
              />
            </SportTag>
            <BackgroundImage
              src={sports?.sports[0].strSportThumb}
              alt={sports?.sports[0].strFormat}
            />    
            <Image
              src={sports?.sports[0].strSportThumb}
              alt={sports?.sports[0].strFormat}
            />
            <div className="min-h-2/3 z-0"></div>
            {!loading && (
              <Label>
                {sports?.sports && sports?.sports[0].strSport}
              </Label>
            )}
          </animated.div>
          {!loading && (
            <LikeContainer>
              <DislikeButton
                onClick={() => sendLike(false)}
              >                
                <CloseIcon width="19.43" height="19.43"/>
              </DislikeButton>
              <LikeButton
                onClick={() => sendLike(true)}
              >
                <HeartIcon width="36.45px" height="34.63px" />
              </LikeButton>
            </LikeContainer>
          )}
        </div>
      ) : !loadingSports ? (        
        <Loading isOpen={true}>
          <LikeButton onClick={() => sendLike(true)}>
            Like  
          </LikeButton>
        </Loading>
        ) : (
          <div>
            No Hay
          </div>
        )
  )
}

export default Home
