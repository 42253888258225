import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html,
  body {
    background: ${(p: any) => p.theme.bg};
    color: ${(p: any) => p.theme.color};
  },
  small {
    font-size: 16px!important;
  },
  h1 {
    font-size: 48px;
  }
`
export default GlobalStyle