const ClockIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.5 0.125C7.64581 0.125 5.83325 0.674834 4.29153 1.70497C2.74982 2.73511 1.54821 4.19929 0.838634 5.91234C0.129062 7.6254 -0.0565943 9.5104 0.305142 11.329C0.666879 13.1475 1.55976 14.818 2.87088 16.1291C4.182 17.4402 5.85246 18.3331 7.67103 18.6949C9.48961 19.0566 11.3746 18.8709 13.0877 18.1614C14.8007 17.4518 16.2649 16.2502 17.295 14.7085C18.3252 13.1668 18.875 11.3542 18.875 9.5C18.8722 7.01446 17.8836 4.63152 16.126 2.87398C14.3685 1.11643 11.9855 0.127811 9.5 0.125ZM13.9194 6.18541L10.0525 10.0524C9.97992 10.125 9.8938 10.1825 9.79902 10.2218C9.70423 10.2611 9.60264 10.2813 9.50004 10.2813C9.39744 10.2813 9.29585 10.2611 9.20106 10.2218C9.10627 10.1826 9.02014 10.125 8.94759 10.0525C8.87504 9.97992 8.81749 9.8938 8.77822 9.79901C8.73895 9.70422 8.71874 9.60263 8.71874 9.50004C8.71873 9.39744 8.73894 9.29584 8.77819 9.20105C8.81745 9.10626 8.875 9.02014 8.94754 8.94759L12.8145 5.08058C12.8871 5.00803 12.9732 4.95048 13.068 4.91122C13.1628 4.87195 13.2644 4.85174 13.367 4.85174C13.4696 4.85174 13.5712 4.87194 13.6659 4.9112C13.7607 4.95046 13.8469 5.00801 13.9194 5.08055C13.992 5.1531 14.0495 5.23922 14.0888 5.33401C14.128 5.42879 14.1482 5.53038 14.1482 5.63298C14.1482 5.73557 14.128 5.83716 14.0888 5.93195C14.0495 6.02674 13.992 6.11286 13.9194 6.18541H13.9194Z" />
    </svg>


  )
}

export default ClockIcon;