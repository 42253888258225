import { createSlice } from "@reduxjs/toolkit"

export interface LoadingState {
  isLoading: boolean
}

const initialState: LoadingState = {
  isLoading: false
}

const LoadingSlice = createSlice({
  name: 'user',
  initialState,  
  reducers: {
    showLoading: (state) => {
      state.isLoading = true
    },
    hideLoading: (state) => {
      state.isLoading = false
    },
  }
})

export default LoadingSlice.reducer
export const { showLoading, hideLoading } = LoadingSlice.actions