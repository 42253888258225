import { useState } from "react";
import GlobalStyle from './GlobalStyle'
import Tabs from './Tabs'

interface Props {
  children: JSX.Element | JSX.Element[];
}

const Layout = ({ children }: Props) => {
  const [theme, setTheme] = useState<string>('light');
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light'
    setTheme(newTheme)
  }

  return (
    <>
      <GlobalStyle />
      {children}
      <Tabs />
    </>
  )
}

export default Layout
