import { NavLink } from "react-router-dom"
import styled from "styled-components"
import HomeIcon from "../icons/HomeIcon"
import AgendaIcon from "../icons/AgendaIcon"
import ClockIcon from "../icons/ClockIcon"
import UserIcon from "../icons/UserIcon"

const Box = styled.div.attrs({
  className: `fixed flex justify-between items-center`
})`
  background-color: ${(p: any) => p.theme.bgNav};
  left: 20px;
  right: 20px;
  bottom: 20px;
  border-radius: 24px;
  height: 85px;
  padding: 12px;
  color: ${(p: any) => p.theme.bgNav};
`

const TabItems = styled.div`
  padding: 0;
`

const StyledNavLink = styled(NavLink).attrs({
  className: `flex items-center justify-center`
})`
  width: 59px;
  height: 59px;
  border-radius: 16px;
  fill: ${(p: any) => p.theme.tabIconColor}; 
  &.active { 
    background-color: ${(p: any) => p.theme.tabActiveBackground};
    fill: ${(p: any) => p.theme.tabActiveIconColor}; 
  }
`

const Tabs = () => {
  return (
    <Box>
      <StyledNavLink
        exact
        to="/"
      >
        <TabItems>
          <HomeIcon />
        </TabItems>
      </StyledNavLink>
      <StyledNavLink
        exact
        to="/history"
      >
        <TabItems>
          <ClockIcon />
        </TabItems>
      </StyledNavLink>
      <StyledNavLink
        exact
        to="a"
      >
        <TabItems>
          <AgendaIcon />
        </TabItems>
      </StyledNavLink>
      <StyledNavLink
        exact
        to="c"
      >
        <TabItems>
          <UserIcon />
        </TabItems>
      </StyledNavLink>
    </Box>
  )
}

export default Tabs